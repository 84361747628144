$template: right_col;
$grid_template: container;
$blockstyle: board;
$mainfont: Mukta Malar;
$template_color_scheme: light;
$header: cover;
$spacing: 133;
$primary-color: #4C16B1;
$accent-color: #C02687;
$dark-color: #282919;
$light-color: #FDFFE5;
$success-color: #16B132;
$error-color: #B14416;
$nav-height: 93;
$button_class: btn-block;
$container_class: container;
$container_width: 2037;
$headersclass: title-header;
$block_headersclass: block-header-box;
$navstyle: center;
$navtype: links;
$nav_panel_class: nav-panel;
$navclass: navbar;
$logo_class: brand;
$mob_menu_toogler_class: mob-menu-button;
$nav_item_class: menu-item;
$nav_link_class: menu-button;
$game_container_class: game-frame;
$game_section_class: gamelist-setion;
$gamelist_class: container-games;
$game_teaser_class: game-block;
$games_play_button_class: play-now-btn;
$game_description_class: game-description-section;
$main_content: gen-content;
$main_section_class: block-main;
$sidebar_section_class: section-sbar;
$count_games_block: 6;
$count_posts_block: 6;
$count_games_sideblock: 5;
$count_posts_sideblock: 4;
$game_more_button: false;
$rounded: true;
$rounded_radius: 1;
$news_show_date: false;
$news_show_button: true;
$news_read_more_right_align: false;
$news_read_more_btn: false;
$news_show_teaser: false;
$news_btn_read_class: news-item-btn;
$news_section_class: news-section;
$news_section_list_class: articles-list;
$news_teaser_class: news-teaser;
$news_description_class: news-description;
$news_date_class: posted-at;
$news_teaser_content_class: news-description-body;
$news_teaser_image_class: news-description-picture;
$footer_class: site-footer;
$disclamer_class: disclamer_at-footer;
$copyright_class: copy-section;
$bottom_nav_class: footer-menu;
$main_page_first_block: news;
$main_title_aligment_center: true;
$side_title_aligment_center: false;
$content_title_aligment_center: true;
$copyright_aligment_center: false;
$disclamer_aligment_center: false;
$section_blog_class: best-post-block;
$mainpost_class: main-post;
$section_blog_bg_class: img-best-post-block;
$news_block_style_orientation: vertical;
$news_block_style_image_frame: false;
$news_block_style_aligment: right;
$blocks_shadow: false;
$blocks_shadow_opacity: 26;
$blocks_borders: false;
$blocks_borders_width: 4;
$blocks_glow: false;
$games_show_play_button: big-button;
$text_page_content_class: article-content;
$news_main_image_class: main-image-of-article;
$news_main_image_container_class: article-image-wrapper;
$mob_menu_id: mobile-menu;
