// @import "helpers/mixins";
// @import "helpers/functions";
// @import "vendor/libs";
@import "../../node_modules/normalize-css/normalize";
@import "helpers/gen_variables";
@import "base/fonts";
@import "base/general";
// @import "../blocks/components/components";
// @import "../blocks/modules/modules";

body {
  font-family: $mainfont;
}
