$spacing: $spacing / 100;
.hidden {
  display: none;
}

$light: #eee;

$background-color: map-get(
  (
    dark: $dark-color,
    darkaccent: $dark-color,
    light: $light-color,
    lightaccent: $light-color,
  ),
  $template_color_scheme
);

$main-color: map-get(
  (
    dark: lighten($dark-color, 5%),
    darkaccent: $primary-color,
    light: lighten($light-color, 3%),
    lightaccent: $primary-color,
  ),
  $template_color_scheme
);

$main-contrast-color: map-get(
  (
    dark: $light-color,
    darkaccent: $light-color,
    light: $dark-color,
    lightaccent: $dark-color,
  ),
  $template_color_scheme
);

$elements-color: map-get(
  (
    dark: $dark-color,
    darkaccent: darken($primary-color, 25%),
    light: lighten($light-color, 3%),
    lightaccent: $primary-color,
  ),
  $template_color_scheme
);

$slave-color: map-get(
  (
    dark: lighten($dark-color, 2%),
    darkaccent: darken($primary-color, 10%),
    light: $primary-color,
    lightaccent: darken($primary-color, 10%),
  ),
  $template_color_scheme
);

$slave-contrast-color: map-get(
  (
    dark: $light-color,
    darkaccent: $light-color,
    light: $light-color,
    lightaccent: $light-color,
  ),
  $template_color_scheme
);

$accent-light: map-get(
  (
    dark: lighten($accent-color, 35%),
    darkaccent: $accent-color,
    light: $accent-color,
    lightaccent: lighten($accent-color, 25%),
  ),
  $template_color_scheme
);

$blocks-color: map-get(
  (
    dark: $main-color,
    darkaccent: lighten($dark-color, 3%),
    light: $main-color,
    lightaccent: darken($light-color, 5%),
  ),
  $template_color_scheme
);

@mixin _media_1000 {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin _media_more_1000 {
  @media (min-width: 1000px) {
    @content;
  }
}

@mixin _media_600 {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin _media_800 {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin border-radius() {
  @if $rounded {
    border-radius: #{$rounded_radius}px;
    overflow: hidden;
    img {
      display: block;
    }
  }
}

@mixin border-line() {
  @if $blocks_borders {
    border: #{$blocks_borders_width}px solid $main-color;
  }
}

@mixin block-shadow($shadow: true, $glow: true) {
  $shadowstyle: "";
  @if $blocks_shadow or $blocks_glow {
    @if blocks_shadow and $shadow {
      $shadowstyle: 0px
        #{$spacing/2}rem
        #{$spacing/2}rem
        rgba(0, 0, 0, $blocks_shadow_opacity/100);
    }
    @if blocks_shadow and $shadow and blocks_glow and $glow {
      $shadowstyle: #{$shadowstyle + ","};
    }
    @if blocks_glow and $glow {
      $shadowstyle: #{$shadowstyle}
        0px
        0
        #{$spacing/2}rem
        transparentize($accent-color, 0.5);
    }
    box-shadow: $shadowstyle;
  }
}

@mixin block-style {
  @if $blockstyle == "board" {
    background: $blocks-color;
    padding: #{$spacing}rem;
    @include _media_1000 {
      padding: #{$spacing}rem;
    }
    margin-top: #{$spacing * 1.2}rem;
    @include border-radius;
  }
}

.modal-overlay,
.sidenav-overlay {
  position: fixed;
  z-index: 999;
  top: -25%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;
  will-change: opacity;
}
.sidenav-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  padding: 0;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  will-change: top, opacity;
  height: 100% !important;
  top: 0px !important;
  &.grid-modal {
    display: grid !important;
  }
  grid-template-columns: minmax(max-content, 600px);
  grid-template-rows: max-content;
  justify-content: center;
  align-content: center;
  pointer-events: none;
  &:focus-visible {
    outline: none;
  }
  .modal-title {
    text-align: center;
    padding: #{$spacing * 2}rem;
    font-size: #{$spacing * 2}rem;
  }
  .#{$button_class} {
    background-color: $error-color;
    &.modal-close {
      background-color: $success-color;
    }
  }
}

.modal-content {
  background: $elements-color;
  pointer-events: auto;
  @if $template_color_scheme == "dark" or $template_color_scheme == "light" {
    color: $main-contrast-color;
  } @else {
    color: $slave-contrast-color;
  }
  @include border-radius();
  @include block-shadow();
  @include border-line();
}
.modal-footer {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: center;
  grid-gap: #{$spacing}rem;
}
html {
  font-size: #{$spacing + 14.5}px;
  //font-size: 16px;
  line-height: 1.3;
  @include _media_1000 {
    font-size: #{$spacing/3 + 1.1}vw;
  }
  @include _media_800 {
    font-size: 2vw;
  }
  @include _media_600 {
    font-size: 3vw;
  }
}

body * {
  box-sizing: border-box;
}

body {
  background-color: $background-color;
  max-width: 100%;
  color: $main-contrast-color;
}

h3 {
  font-size: #{$spacing * 1.2}rem;
}

a {
  color: $accent-light;
  &:hover {
    color: lighten($accent-light, 10%);
  }
}
.text-center {
  text-align: center !important;
}
.large-text {
  font-size: #{$spacing * 1.5}rem;
}
.#{$nav_panel_class} {
  @include block-shadow(true, false);
  background: $main-color;
  position: relative;
  z-index: 1;
  @include _media_1000 {
    @if $grid_template == "sidemenu" {
      order: -1;
    }
  }
}
.img-fluid {
  max-width: 100%;
}

.#{$headersclass} {
  text-align: map-get(
    (
      true: center,
      false: left,
    ),
    $main_title_aligment_center
  );
}

h1.#{$headersclass} {
  margin-top: #{$spacing * 3}rem;
}

@include _media_1000 {
  h1.#{$headersclass},
  .#{$game_description_class} {
    padding: 0 #{$spacing * 2}rem;
  }
}

.#{$button_class} {
  background: $accent-color;
  text-decoration: none;
  padding: #{$spacing * 0.8}rem #{$spacing * 1.6}rem;
  display: block;
  margin: #{$spacing}rem auto;
  max-width: 300px;
  text-align: center;
  width: 100%;
  font-size: #{$spacing * 1.1}rem;
  @include border-radius();
  @include block-shadow(true, false);
  box-sizing: border-box;
  color: $light-color;
  &:hover {
    color: $light-color;
  }
}

.#{$container_class} {
  width: 100%;
  max-width: #{$container_width}px;
  margin: 0px auto;
  padding: 0 #{$spacing}rem;
  @if $blockstyle == "board" {
    @include _media_1000 {
      padding: 0;
    }
  }
  box-sizing: border-box;
}
.#{$navclass},
##{$mob_menu_id} {
  display: grid;
  grid-template-rows: minmax(#{$nav-height}px, min-content);
  @include _media_1000 {
    grid-template-columns: 1fr #{$nav-height}px;
  }
  @if $grid_template == "sidemenu" and $template != "full" {
    // @include _media_1000 {/////??????????????????????????????????????????????????????????????????????????????? чё то там лого хз
    .#{$logo_class} {
      text-align: center;
    }
    //}

    ul {
      justify-self: center;
      &.after-logo {
        margin-top: 0;
      }
    }
  } @else {
    grid-auto-flow: column;
    //grid-template-rows: #{$nav-height}px;
    ul {
      justify-self: flex-end;
      grid-auto-flow: column;
    }
  }

  @if $grid_template ==
    "sidemenu" and
    $template ==
    "full" and
    $navstyle ==
    "center"
  {
    //justify-content: center;
    //justify-items: center;
    grid-template-columns: 1fr max-content 1fr;
    ul.after-logo {
      justify-self: flex-start;
    }
  }

  @if $navstyle == "center" and $grid_template != "sidemenu" {
    justify-content: center;
    justify-items: center;
    grid-template-columns: 1fr;
    @include _media_more_1000 {
      grid-template-columns: 1fr auto 1fr;
    }
  }
  .#{$logo_class} {
    max-width: 100%;
    max-height: 100%;
    padding: #{$spacing * 0.6}rem #{$spacing * 1.5}rem;
    @if $grid_template != "sidemenu" and $template != "full" {
      justify-self: map-get(
        (
          left: flex-start,
          center: center,
        ),
        $navstyle
      );
    }
    align-self: center;
    // @include _media_1000 {
    //   justify-self: left;
    // }
    img {
      //min-height: 60px;
      max-height: #{$nav-height - 20}px;
    }
  }
  ul {
    @include _media_1000 {
      display: none;
      &.#{$mob_menu_id} {
        ////////////////mobmenu
        display: grid;
        grid-auto-flow: row;
        width: 100%;
        justify-items: center;
        justify-content: center;
        margin: #{$spacing * 3}rem 0;
        li {
          width: 100%;
          text-align: center;
          a {
            font-size: #{$spacing * 1.3}rem;
          }
        }
      }
    }
    display: grid;
    padding: 0;
    margin: 0;
    @if $navtype != "blocks" {
      align-content: center;
      grid-gap: #{$spacing}rem;
    }
    @if (
        $navtype ==
          "buttons" and
          $navstyle ==
          "right" and
          $grid_template !=
          "sidemenu"
      ) or
      (
        $template ==
          "full" and
          $grid_template ==
          "sidemenu" and
          $navstyle !=
          "center"
      )
    {
      margin-right: #{$spacing * 1.5}rem;
    }
    @if $navstyle == "center" and $grid_template != "sidemenu" {
      &:last-child {
        justify-self: flex-start;
      }
    }
    @if $grid_template == "sidemenu" and $template != "full" {
      text-align: center;
      margin-top: #{$spacing}rem;
    }

    li.#{$nav_item_class} {
      white-space: nowrap;
      list-style: none;
      transition: 0.5s;
      a.#{$nav_link_class} {
        @if $navtype == "buttons" {
          @include border-radius();
          background: $slave-color;
          color: $slave-contrast-color;
        } @else {
          color: map-get(
            (
              dark: $slave-contrast-color,
              darkaccent: $main-contrast-color,
              light: $slave-color,
              lightaccent: $slave-contrast-color,
            ),
            $template_color_scheme
          );
        }
        @if $grid_template ==
          "sidemenu" and
          $navtype ==
          "links" and
          $template !=
          "full"
        {
          padding: 0;
        } @else {
          padding: #{$spacing}rem;
        }
        text-decoration: none;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        align-content: center;
      }
      &:hover {
        //background: lighten($color: #000000, $amount: 0);
        //background: darken($color: #000000, $amount: 0);
        @if $navtype != "links" {
          a {
            background: map-get(
              (
                dark: lighten($dark-color, 10%),
                darkaccent: lighten($accent-color, 1%),
                light: darken($slave-color, 5%),
                lightaccent: $accent-color,
              ),
              $template_color_scheme
            );
          }
        }
        @if $navtype == "blocks" {
          a {
            color: $slave-contrast-color;
          }
        }
        @if $navtype == "links" {
          a {
            color: map-get(
              (
                dark: darken($slave-contrast-color, 30%),
                darkaccent: lighten($accent-color, 10%),
                light: lighten($primary-color, 10%),
                lightaccent: lighten($accent-color, 30%),
              ),
              $template_color_scheme
            );
          }
        }
      }
    }
  }
  .#{$mob_menu_toogler_class} {
    @extend .#{$button_class};
    padding: 10px;
    width: auto;
    display: none;
    justify-self: flex-end;
    position: absolute;
    //background: #456;
    align-self: center;
    margin-right: #{$spacing}rem;
    svg {
      color: $light-color;
      width: #{$nav-height * 0.6}px;
      height: #{$nav-height * 0.6}px;
      display: block;
    }
    @include _media_1000 {
      display: block;
    }
  }
}

.#{$bottom_nav_class} {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max-content, 0));
  margin: 0;
  padding: #{$spacing}rem;
  justify-content: center;
  grid-gap: #{$spacing}rem;
  .#{$nav_item_class} {
    list-style: none;
    .#{$nav_link_class} {
      display: block;
      white-space: nowrap;
      text-decoration: none;
    }
  }
}

.#{$game_container_class} {
  position: relative;
  padding: 10px 10px 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
/*
  .#{$game_description_class} {
    @include _media_1000 {
      padding: 0 #{$spacing * 2}rem;
    }
  }
*/ // game description

.#{$main_section_class} {
  .#{$headersclass} {
    text-align: map-get(
      (
        true: center,
        false: left,
      ),
      $content_title_aligment_center
    );
  }
  @if $template != "full" {
    display: grid;
    gap: #{$spacing}rem;
    @if $news_block_style_orientation ==
      "horizontal" and
      $main_page_first_block ==
      "games" and
      $grid_template !=
      "sidemenu"
    {
      grid-template-columns: map-get(
        (
          left_col: 1fr 3fr,
          right_col: 3fr 1fr,
        ),
        $template
      );
    } @else {
      @include _media_1000 {
        grid-template-columns: auto;
      }
      grid-template-columns: map-get(
        (
          left_col: minmax(min-content, #{$spacing * 200}px) 1fr,
          right_col: 1fr minmax(min-content, #{$spacing * 200}px),
          /* left_col:
            300px
            1fr,
          right_col: 1fr 300px*/
        ),
        $template
      );
    }
  }
}

.#{$sidebar_section_class} {
  @include _media_1000 {
    order: 1;
  }
  .#{$headersclass}.#{$block_headersclass} {
    color: $slave-contrast-color;
    text-align: map-get(
      (
        true: center,
        false: left,
      ),
      $side_title_aligment_center
    );
    display: block;
    margin: 0;
    background: $slave-color;
    padding: #{$spacing}rem !important;
    margin-bottom: #{$spacing * 1.2}rem;
  }
  .#{$game_section_class},
  .#{$news_section_class} {
    @if $grid_template != "sidemenu" and $blockstyle == "board" {
      h3 {
        font-size: #{$spacing * 1}rem;
        padding: #{$spacing}rem;
        margin-top: -#{$spacing}rem !important;
        margin-left: -#{$spacing}rem !important;
        margin-right: -#{$spacing}rem !important;
      }
    }
  }
}
.#{$main_content} {
  .#{$headersclass} {
    @include _media_1000 {
      &.#{$block_headersclass} {
        padding: 0px #{$spacing * 2}rem; ////????????????????????????????????
      }
    }
  }
}

@if $blockstyle == "board" {
  .#{$main_content} {
    .#{$game_section_class},
    .#{$news_section_class} {
      padding: #{$spacing * 2}rem;
    }
  }
}

.#{$game_section_class} {
  margin-bottom: #{$spacing * 2}rem;
  @include block-style();
  .#{$gamelist_class} {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: #{$spacing}rem;
    justify-content: space-between;
    .#{$game_teaser_class} {
      @include border-line();
      @include border-radius();
      @include block-shadow();
      background: $elements-color;
      text-align: center;
      position: relative;
      a {
        display: block;
      }
      @if $games_show_play_button == "hover-button" {
        display: grid;
        &:hover a.#{$button_class} {
          opacity: 1;
        }
      }
      a.#{$button_class} {
        transition: 0.2s;
        @if $games_show_play_button {
          @if $games_show_play_button == "sticked" {
            margin: 0px auto;
            max-width: none;
          }
          @if $games_show_play_button == "hover-button" {
            opacity: 0;
            position: absolute;
            margin: 0px;
            align-self: center;
            justify-self: center;
            width: auto;
          }
          @if $games_show_play_button == "small-button" {
            padding: #{$spacing * 0.3}rem #{$spacing * 0.7}rem;
            margin: #{$spacing * 0.4}rem;
            width: auto;
            display: inline-block;
          }
          @if $games_show_play_button == "button-on-picture" {
            max-width: none;
            padding: #{$spacing * 0.3}rem #{$spacing * 0.7}rem;
            position: absolute;
            bottom: 0;
            margin: 0;
            background: transparentize($color: $accent-color, $amount: 0.2);
            &:hover {
              background: $accent-color;
            }
          }
          @if $games_show_play_button == "big-button" {
            max-width: 90%;
          }
        }
      }
      .#{$game_teaser_class}-image img {
        width: 100%;
        display: block;
        @include border-radius();
      }
    }
  }
}
.#{$news_section_class} {
  @include block-style();
  margin-bottom: #{$spacing * 2}rem;
  .#{$news_section_list_class} {
    display: grid;
    @if $news_block_style_orientation == "horizontal" {
      grid-template-columns: repeat(auto-fit, minmax(#{$spacing * 350}px, 1fr));
      @include _media_600 {
        grid-template-columns: 1fr;
      }
    } @else {
      grid-template-columns: repeat(auto-fit, minmax(#{$spacing * 200}px, 1fr));
    }
    grid-gap: #{$spacing}rem;

    .#{$news_teaser_class} {
      @include border-radius();
      @include border-line();
      @include block-shadow();
      background: $elements-color;
      @if $template_color_scheme == "dark" or $template_color_scheme == "light"
      {
        color: $main-contrast-color;
      } @else {
        color: $slave-contrast-color;
      }

      display: grid;

      @if $news_block_style_orientation == "vertical" {
        grid-template-rows: max-content;
      }
      @if $news_block_style_orientation == "horizontal" {
        gap: #{$spacing/2}rem;
        grid-template-columns: 1fr 1fr;
        @if $news_block_style_aligment == "right" {
          .#{$news_teaser_image_class} {
            order: 1;
          }
        }
        .#{$news_teaser_image_class} img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      } @else if ($news_block_style_image_frame) {
        .#{$news_teaser_image_class} {
          margin-bottom: #{$spacing}rem;
        }
      }

      @if $news_block_style_image_frame == true {
        padding: #{$spacing}rem;
      }
      .#{$headersclass} {
        text-align: left;
        display: inline-block;
        font-weight: 600;
        line-height: 1.2;
      }
      a {
        text-decoration: none;
      }
      @if $news_show_button {
        .#{$news_btn_read_class} {
          align-self: flex-end !important;
          @if $news_read_more_right_align {
            text-align: right;
          }
          @if $news_read_more_btn {
            a {
              @extend .#{$button_class};
              font-size: #{$spacing * 0.9}rem;
              padding: #{$spacing * 0.3}rem #{$spacing * 0.5}rem;
              display: inline-block;
              width: auto;
              margin: 0;
            }
          }
        }
      }
      .#{$news_teaser_image_class} {
        @include border-radius();
      }
      .#{$news_teaser_content_class} {
        display: grid;
        grid-template-rows: max-content;
        gap: #{$spacing * 0.1 + 0.5}rem;
        @if $news_block_style_image_frame == false {
          padding: #{$spacing}rem;
        }
      }
      .#{$news_date_class} {
        font-size: 0.7rem;
        @if (
          $template_color_scheme == "light" or $template_color_scheme == "dark"
        ) {
          color: transparentize($color: $main-contrast-color, $amount: 0.5);
        } @else {
          color: transparentize($color: $slave-contrast-color, $amount: 0.5);
        }
        svg {
          width: 0.7rem;
          height: 0.7rem;
          margin-right: 0.3rem;
        }
      }
    }
  }
}

.#{$news_main_image_container_class} {
  height: 60vh;
  .#{$news_main_image_class} {
    @include border-radius();
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.#{$footer_class} {
  background: $main-color;
  margin-top: #{$spacing}rem;
  color: $slave-contrast-color;
  .#{$copyright_class} {
    background: $slave-color;
    padding: #{$spacing}rem 0;
    span {
      padding: 0 #{$spacing * 2}rem;
    }
    text-align: map-get(
      (
        true: center,
        false: left,
      ),
      $copyright_aligment_center
    );
  }
  .#{$disclamer_class} {
    padding: #{$spacing}rem 0;
    padding: #{$spacing}rem #{$spacing * 2}rem;
    @if $template_color_scheme == "dark" or $template_color_scheme == "light" {
      color: $main-contrast-color;
    } @else {
      color: $slave-contrast-color;
    }
    text-align: map-get(
      (
        true: center,
        false: left,
      ),
      $disclamer_aligment_center
    );
  }
  .#{$bottom_nav_class} {
    @include _media_1000 {
      display: none;
    }
  }
}

.#{$section_blog_class} {
  position: relative;
  .#{$section_blog_bg_class} {
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .#{$mainpost_class} {
    background: transparentize($color: $slave-color, $amount: 0.5);
    position: relative;
    padding: #{$spacing}rem;
    & * {
      color: $slave-contrast-color;
    }
    box-shadow: inset 0 0 #{$spacing * 4}rem rgba(0, 0, 0, $spacing * 27/100);

    .#{$mainpost_class}-body {
      max-width: 1000px;
      width: 90%;
      margin: 0px auto;
    }

    .#{$headersclass} {
      font-size: #{$spacing * 1.5}rem;
    }
    .#{$headersclass} a {
      text-decoration: none;
      color: $light;
    }
  }
}

.#{$text_page_content_class} {
  @include border-radius();
  @include border-line();
  @include block-shadow(true, false);
  background-color: $blocks-color;
  padding: #{$spacing * 2}rem;
  margin-bottom: #{$spacing * 2}rem;
}

##{$mob_menu_id} {
  @include block-shadow(true, false);
  @include border-line();
  display: block;
  position: fixed;
  width: #{$spacing * 220}px;
  max-width: 300px;
  right: 0;
  top: 0;
  margin: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  height: 100%;
  background-color: $main-color;
  z-index: 9999;
  overflow-y: auto;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(105%);
  transform: translateX(105%);
  @include _media_more_1000 {
    display: none;
  }
}
