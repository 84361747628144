@font-face {
  font-family: 'Mukta Malar';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMwBuAG4W4.ttf) format('truetype');
}
@font-face {
  font-family: 'Mukta Malar';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINUBeAG4W4.ttf) format('truetype');
}
@font-face {
  font-family: 'Mukta Malar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muktamalar/v12/MCoXzAXyz8LOE2FpJMxZqIv_J_A.ttf) format('truetype');
}
@font-face {
  font-family: 'Mukta Malar';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMMBOAG4W4.ttf) format('truetype');
}
@font-face {
  font-family: 'Mukta Malar';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMgA-AG4W4.ttf) format('truetype');
}
@font-face {
  font-family: 'Mukta Malar';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINEAuAG4W4.ttf) format('truetype');
}
@font-face {
  font-family: 'Mukta Malar';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINYAeAG4W4.ttf) format('truetype');
}
